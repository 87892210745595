<template>
  <div>
    <div class="chat_row" v-for="c in chat" :key="c.id" :class="getChatClass(c)">
      <p v-if="c.type==='div'" class="chat_date">
        <span>{{ c.date }}</span>
      </p>
      <div
        v-if="c.type==='txt'||c.type==='img'||c.type==='ogp'"
        class="chat_post"
        :class="getChatClass(c)"
      >
        <figure v-if="!isMyChat(c)" class="chat_post_icon">
          <img :src="profileImage" width="55" height="55" alt />
        </figure>
        <div class="chat_post_cnt">
          <!-- <p class="chat_post_name"><em>JC3事務局</em></p> -->
          <ul class="chat_post_msg">
            <li v-if="c.type==='txt'">
              <p class="chat_post_text" v-html="autoLink(c.message)"></p>
              <div class="chat_post_info">
                <time datetime>{{ c.post_datetime | formatDateTime }}</time>
              </div>
            </li>
            <li v-if="c.type==='img'">
              <figure class="chat_post_img">
                <img :src="c.img_src" alt height="240" />
              </figure>
              <div class="chat_post_info">
                <span>{{ c.img_size }}</span>
                <time datetime>{{ c.post_datetime | formatDateTime }}</time>
              </div>
            </li>
            <li v-if="c.type==='ogp'">
              <figure class="chat_post_ogp">
                <a class="chat_post_ogp_wrap" :href="c.url" target="_blank" rel="noreferrer">
                  <div class="chat_post_ogp_img">
                    <img :src="c.ogp_img" width="1200" height="640" alt />
                  </div>
                  <figcaption class="chat_post_ogp_cap">
                    <em>{{ c.ogp_title }}</em>
                    <p>{{ c.ogp_desc }}</p>
                  </figcaption>
                </a>
              </figure>
              <div class="chat_post_info">
                <time datetime>{{ c.post_datetime | formatDateTime }}</time>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const now = new Date();

export default {
  props: {
    isAdmin: {
      type: Boolean
    },
    chat: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      profileImage: ''
    };
  },
  computed: {
    profileSrc() {
      if (this.isAdmin) {
        return this.profile.src;
      } else {
        return "/img/mypage/img-chat-jc3.png";
      }
    }
  },
  created() {
    this.setProfileImage();
  },
  filters: {
    formatDateTime(value) {
      var iscurrentDate = moment(value).isSame(now, "day");
      if (iscurrentDate) {
        return moment(value).format("HH:mm");
      } else {
        return moment(value).format("YYYY-MM-DD HH:mm");
      }
    }
  },
  methods: {
    autoLink(value) {
      return typeof value === "string" || value instanceof String
        ? value.replace(
            /(https?:\/\/[^\s]*)/g,
            '<a href=\'$1\' target="_blank" rel="noreferrer">$1</a>'
          )
        : "";
    },
    getChatClass(chat) {
      if (this.isMyChat(chat)) {
        return { "-right": true };
      } else {
        return { "-left": true };
      }
    },
    isMyChat(chat) {
      if (this.isAdmin) {
        if (chat.is_admin) {
          return true;
        } else {
          return false;
        }
      } else {
        if (chat.is_admin) {
          return false;
        } else {
          return true;
        }
      }
    },
    setProfileImage() {
      if (this.isAdmin) {
        this.fetchProfileImage()
      } else {
        this.profileImage = "/img/mypage/img-chat-jc3.png";
      }
    },
    fetchProfileImage() {
      this.$authAxios
        .get(`/api/chat/${this.$route.params.id}/image/profile`, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type });
          this.profileImage = URL.createObjectURL(blob);
        })
        .catch(() => {
          this.profileImage = "/img/cmn/img-profile.jpg";
        });
    },
  }
};
</script>