<template>
  <section class="chat">
    <h2 class="chat_ttl">メンバー：JC3事務局</h2>
    <div class="chat_wrap" id="chat">
      <div :class="{c_load: loading}"></div>
      <chat-messages :isAdmin="isAdmin" :chat="chat"></chat-messages>
      <p v-if="unread_chat.length" class="chat_more">以下未読のメッセージ</p>
      <chat-messages :isAdmin="isAdmin" :chat="unread_chat"></chat-messages>
      <chat-messages :isAdmin="isAdmin" :chat="new_chat"></chat-messages>
      <p
        v-if="!loading&&!chat.length&&!unread_chat.length&&!new_chat.length"
        class="chat_more"
      >メッセージを送信しましょう</p>
    </div>
    <div class="chat_form">
      <textarea class="chat_form_msg" placeholder="メッセージを入力する" v-model="message"></textarea>
      <div class="chat_form_btn">
        <input type="file" ref="file" style="display: none;" @change="selectFile" />
        <button class="chat_form_btn_img" type="button" @click="selectImage">
          <img src="/img/mypage/img-attachment@2x.png" width="32" height="32" alt />
        </button>
        <button class="c_btn chat_form_btn_submit" type="button" @click="send">
          <img src="/img/mypage/img-submit@2x.png" width="15" height="15" alt /> 送信
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import ChatMessages from "@/components/ChatMessages";

const reg_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;
moment.locale("ja");

export default {
  components: {
    ChatMessages
  },
  props: ["isAdmin"],
  data() {
    return {
      chat: [],
      unread_chat: [],
      new_chat: [],

      message: "",
      connection: null,
      base_date: undefined,
      loading: false
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  created() {
    this.connectWebSocket();
    this.fetchChat();
  },
  mounted() {
    const chatdiv = document.getElementById("chat");
    chatdiv.scrollTop = chatdiv.scrollHeight;
  },
  methods: {
    async connectWebSocket() {
      const accessToken = await this.$auth.getTokenSilently();
      console.log("Starting connection to WebSocket Server");
      this.connection = new WebSocket(
        "wss://" +
          location.hostname +
          "/ws/chat/" +
          this.id +
          "?token=" +
          accessToken
      );

      let _this = this;
      this.connection.onmessage = async function(event) {
        const data = JSON.parse(event.data);

        // 日付線
        await _this.checkDateDiv(_this.new_chat, data.post_datetime);

        // 投稿内容
        if (data.type === "txt") {
          data.message = data.message.replace(/\r?\n/g, '<br>');
          _this.new_chat.push(data);
          await _this.$nextTick();
          const chatdiv = document.getElementById("chat");
          chatdiv.scrollTop = chatdiv.scrollHeight;

          // OGP
          await _this.checkOGP(_this.new_chat, data);
        } else if (data.type === "img") {
          await _this.getBase64Image(_this.new_chat, data);
        }
      };

      this.connection.onopen = function() {
        console.log("Successfully connected to the echo websocket server...");
      };
    },
    fetchChat() {
      this.loading = true;
      this.$authAxios
        .get(`/api/chat/${this.id}`)
        .then(async response => {
          this.chat = await this.fixMessage(response.data.chat);
          this.unread_chat = await this.fixMessage(response.data.unread_chat);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(async () => {
          await this.$nextTick();
          const chatdiv = document.getElementById("chat");
          chatdiv.scrollTop = chatdiv.scrollHeight;
        });
    },
    checkDateDiv(list, post_datetime) {
      let _this = this;
      return new Promise(function(resolve) {
        if (
          _this.base_date === undefined ||
          moment(post_datetime).isAfter(_this.base_date, "day")
        ) {
          list.push({
            type: "div",
            date: moment(post_datetime).format("MM月DD日(ddd)")
          });
          _this.base_date = post_datetime;
          resolve();
        } else {
          resolve();
        }
      });
    },
    checkOGP(list, m) {
      let _this = this;
      return new Promise(function(resolve) {
        if (m.type === "txt") {
          let results = m.message.match(reg_url);
          if (results && results.length) {
            let promises = [];
            for (let url of results) {
              promises.push(_this.getOgpRequest(list, url, m));
            }
            Promise.all(promises).then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    getOgpRequest(list, url, m) {
      return this.$authAxios
        .get("/api/ogp", {
          params: { url: url }
        })
        .then(response => {
          if (response.data.title) {
            list.push({
              type: "ogp",
              post_datetime: m.post_datetime,
              url: response.data.url,
              ogp_img: response.data.image,
              ogp_title: response.data.title,
              ogp_desc: response.data.description,
              is_admin: m.is_admin
            });
            this.$nextTick(() => {
              const chatdiv = document.getElementById("chat");
              chatdiv.scrollTop = chatdiv.scrollHeight;
            });
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    async getBase64Image(list, message) {
      await this.$authAxios
        .get(`/api/chat/${this.id}/image/${message.id}`)
        .then(response => {
          message.img_src = response.data;
          list.push(message);
          this.$nextTick(() => {
            const chatdiv = document.getElementById("chat");
            chatdiv.scrollTop = chatdiv.scrollHeight;
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    async fixMessage(messages) {
      let _this = this;
      let fixMessages = [];
      for (let m of messages) {
        await _this.checkDateDiv(fixMessages, m.post_datetime);
        if (m.type === "txt") {
          m.message = m.message.replace(/\r?\n/g, '<br>');
          fixMessages.push(m);
          await _this.checkOGP(fixMessages, m);
        } else if (m.type === "img") {
          await _this.getBase64Image(fixMessages, m);
        }
      }
      return fixMessages;
    },
    send() {
      if (this.message) {
        this.connection.send(
          JSON.stringify({
            type: "txt",
            message: this.message,
            is_admin: this.isAdmin
          })
        );
      }
      this.message = "";
    },
    selectImage() {
      this.$refs.file.click();
    },
    selectFile() {
      const imageFile = this.$refs.file.files[0];
      if (!imageFile) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onload = () => {
        this.connection.send(
          JSON.stringify({
            type: "img",
            image: reader.result,
            message: "",
            is_admin: this.isAdmin
          })
        );
      };

      this.$refs.file.value = null;
    },
    // called by parent component.
    webSocketClose() {
      this.connection.close();
      console.log("Closing connection to WebSocket Server");
    }
  }
};
</script>